import { Box, Flex, Stack, Tag, TagLabel, Text } from "@chakra-ui/react";
import FullScreen from "@components/atom/FullScreen";
import PaymentInfoCard from "@components/molecules/PaymentInfoCard";
import NetworkWrapper from "@components/wrapper/NetworkWrapper";
import { NO_DISPATCH_SERVICE_TYPE } from "@config/constant";
import { useGetCase } from "@hooks/api/useCaseApi";
import { usePaymentInfo } from "@hooks/api/usePayment";
import { useOrder } from "@hooks/api/useSave";
import { Icon } from "@iconify/react/dist/iconify.js";
import usePaymentAction from "@store/action-hook/usePaymentAction";
import { useRedStore } from "@store/store";
import { getValueFromURLParams } from "@utils";
import React, { useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Payment() {
  const { id: orderId } = useParams();
  const isPriceMandatory = useRedStore(state => state.collectPayment.isPriceMandatory);
  const email = useRedStore(state => state.user.email);
  const _updateCurrentPageTitle = useRedStore(state => state._updateCurrentPageTitle);
  const resetPayment = useRedStore(state => state.resetPayment);
  const { convertToBooking, ...rest2 } = useOrder();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    resetPayment();
    _updateCurrentPageTitle("Collect Payment");
  }, []);

  usePaymentInfo({ orderId });
  const { ...rest }: any = useGetCase({
    orderId,
    onSuccess: (data: any) => {
      if (data?.data?.data) {
        // initiatePaymentModule(data?.data?.data);
      }
    },
  });
  const order = useRedStore(state => state.order);
  const is_is_more_then_x_km = useRedStore(state => state.localOrderChanges?._is_more_then_x_km);

  const makeBooking = (cb: any) => {
    if (order?.bookingType !== "BOOKING") {
      if (!rest2?.isLoading) {
        convertToBooking({ onSuccess: cb });
      }
    } else {
      cb();
    }
  };

  function handleCollectCashCb() {
    // 2. Navigate to Case history page
    // 3. Navigate to dispatch page.
    const historyPage = (id: string) => `/case-history/${id}/case-overview`;
    const dispatchPage = (id: string) => `/case/${id}/ready-to-dispatch`;
    const navigateTo = getValueFromURLParams("onSuccessNavigate"); // From History page.

    if (navigateTo) {
      navigate(navigateTo);
    } else if (NO_DISPATCH_SERVICE_TYPE.includes(order.serviceType) || is_is_more_then_x_km) {
      const url = historyPage(order?.orderId);
      navigate(url);
    } else {
      const url = dispatchPage(order?.orderId);
      navigate(url);
    }
  }

  return (
    <NetworkWrapper status={rest}>
      <FullScreen>
        <Stack gap={4}>
          <Stack bg={"purple.50"} borderRadius={"md"} mt={4} borderWidth={1} borderColor={"gray.200"} mx={2} shadow={"sm"}>
            <Flex justify={"space-between"} p={4} align={"center"}>
              <Box>
                <Text fontSize={"2xs"}>Order ID</Text>
                <Text fontSize={"sm"} fontWeight={"semibold"}>
                  {order?.orderId}
                </Text>
              </Box>
              <Box>
                <Text fontSize={"2xs"}>City</Text>
                <Flex borderRadius={"full"}>
                  {/* <Icon icon={"typcn:location"} fontSize={15} /> */}
                  <Text fontSize="sm" fontWeight={"semibold"}>
                    {order.city}
                  </Text>
                </Flex>
              </Box>
              {isPriceMandatory && (
                <Tag onClick={() => makeBooking(handleCollectCashCb)} size={"sm"} variant="subtle" colorScheme={rest2?.isLoading ? "gray" : "red"} h={6} borderRadius={"full"} gap={1.5}>
                  <Icon icon={rest2?.isLoading ? "eva:upload-outline" : "ion:play-skip-forward"} fontSize={15} />
                  <TagLabel fontWeight={"semibold"}>{"Skip"}</TagLabel>
                </Tag>
              )}
            </Flex>
            <Box bg={"purple.100"} px={4} py={2}>
              <Flex justify={"start"} align={"center"}>
                <Text fontSize={"sm"} mr={2} fontWeight={"normal"}>
                  Collected By:
                </Text>
                <Text fontSize={"sm"} fontWeight={"semibold"}>
                  {email}
                </Text>
              </Flex>
            </Box>
          </Stack>

          {order?.fareAgreed && <PaymentInfoCard />}
        </Stack>
      </FullScreen>
    </NetworkWrapper>
  );
}
