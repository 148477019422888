import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { convertPrice } from "@utils";
import { memo } from "react";

const RedMarginPricingPage = memo(({ constToOwner, percentage }: { constToOwner: any; percentage: any }) => {
  return (
    <Box p={2} mt={4} borderWidth={1} borderRadius={"md"} borderColor={"black"} borderStyle={"dashed"}>
      <Flex justify={"space-between"} align={"start"}>
        <Text fontWeight={"normal"} fontSize={"sm"} mt={-0.75}>
          Expected RED Margin if Partner Vehicle is Assigned
        </Text>
      </Flex>
      <Flex justify={"space-between"} align={"start"} mt={4}>
        <Flex align={"center"} color={"primary.500"}>
          <Icon icon={"solar:tag-price-bold"} />
          <Text fontWeight={"semibold"} fontSize={"sm"} mt={-0.75} ml={2}>
            Expected partner cost
          </Text>
        </Flex>

        <Text fontWeight={"bold"} fontSize={"md"}>
          ₹ {convertPrice(constToOwner)}
        </Text>
      </Flex>
      <Flex justify={"space-between"} align={"start"} mt={0}>
        <Flex align={"center"} color={"primary.500"}>
          <Icon icon={"ri:discount-percent-fill"} />
          <Text fontWeight={"semibold"} fontSize={"sm"} mt={-0.75} ml={2}>
            RED Margin
          </Text>
        </Flex>
        <Text fontWeight={"bold"} fontSize={"md"} color={"red"}>
          {percentage}%
        </Text>
      </Flex>
    </Box>
  );
});

export default RedMarginPricingPage;

{
  /* <Flex justify={"space-between"} align={"start"}>
        <Flex justify={"center"} align={"start"}>
          <Icon icon="streamline:discount-percent-badge-solid" fontSize={18} />
          <Text fontWeight={"semibold"} fontSize={"xs"} mt={-0.75}>
            Expected RED Margin if Partner Vehicle is Assigned
          </Text>
        </Flex>
        {reset?.isLoading ? (
          <Icon icon={"eos-icons:three-dots-loading"} fontSize={32} />
        ) : (
          <Stack minW={"20"} justify={"end"} align={"end"}>
            <Text fontWeight={"bold"} fontSize={"md"} color={"red"}>
              {((price + addonPrice - constToOwner) / (price + addonPrice))?.toFixed(2)}%
            </Text>
            <Text fontWeight={"bold"} fontSize={"md"}>
              ₹ {convertPrice(constToOwner)}
            </Text>
          </Stack>
        )}
      </Flex>  */
}
